<template>
  <b-modal
    id="delete-behaviour-modal"
    v-model="localShowBoolean"
    title="Delete Behaviour"
    size="lg"
    centered
    @hidden="onHide"
  >
    <p v-if="selected_bn && selected_bn.details">
      Are you sure you want to delete the behaviour node:
      <span class="text-primary font-weight-bolder">{{ selected_bn.details.name }}</span>
      ?
    </p>

    <p class="text-danger font-weight-bolder">
      This action cannot be undone.
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-form-checkbox
        v-model="deleteChildNodes"
        class="mr-auto"
        :value="true"
        :unchecked-value="false"
      >
        Delete child behaviour nodes
      </b-form-checkbox>

      <b-button variant="outline-secondary" @click="cancel()">
        No, keep it
      </b-button>
      <b-button variant="danger" @click="onSubmit">
        {{ deleteChildNodes ? 'Delete behaviour & all children' : 'Delete behaviour' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DeleteBehaviourNodeModal',
  props: {
    showBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteChildNodes: false,
      localShowBoolean: false,
    }
  },
  computed: {
    ...mapState({
      selected_bn: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
    }),
  },
  watch: {
    showBoolean(newVal) {
      this.localShowBoolean = newVal
    },
  },
  methods: {
    onSubmit() {
      const payload = {
        bt: this.selected_bt.id,
        bn: this.selected_bn.details.id,
        delete_children: this.deleteChildNodes,
      }
      this.$http.post('/api/v2/behaviour/delete_bn', payload)
        .then(({ data }) => {
          this.$emit('deleted-node', data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Behaviour Deleted',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred when attempting to delete the behaviour node',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$bvModal.hide('delete-behaviour-modal')
        })
    },
    onHide() {
      this.$emit('update-bool', this.localShowBoolean)
      this.deleteChildNodes = false
    },
  },
}
</script>
