<template>
  <div>
    <div v-if="copying">
      Copying
      <b-spinner large type="grow" />
    </div>
    <div v-if="moving">
      Moving
      <b-spinner large type="grow" />
    </div>
    <div v-if="integrating">
      Integrating
      <b-spinner large type="grow" />
    </div>
    <div v-if="testgen">
      Generating Tests
      <b-spinner large type="grow" />
    </div>

    <div>
      <div id="tree" ref="tree" />
    </div>

    <AddBehaviourModal
      id="add-behaviour-modal"
      title="Add Behaviour"
      @added-node="postAdd"
    />
    <AddBehaviourModal
      id="add-precond-modal"
      title="Add Precondition"
      p_type="Selection"
      p_direction="before"
      @added-node="postAddPrecond"
    />
    <edit-behaviour @updated-node="postUpdate" />
    <EditTimingConstraint
      :selected-b-ns="selectedNodeIds"
      @updated-timing="postTiming"
    />
    <delete-behaviour @deleted-node="postDel" />
    <InterfaceSelect @input="instantiateInterface" />
    <FunctionSelectModal @sel-fn="instantiateFn1" />
    <InstantiateFunctionBehaviour
      id="instantiate-fn-modal"
      title="Instantiate Function"
      p_type="Event"
      p_direction="after"
      :p_performers="instFnPerformers"
      :p_behaviour="instFnName"
      :p_reqs="instFnReqs"
      :p_iss="instFnIss"
      @instantiated="postAdd"
    />
    <CopyBehaviour @copy-node="postCopy" />
    <ShowTestsModal @testedness="showTests" />
    <ViewRequirementModal :bt="selectedBT.id" @requirementSelected="highlight_nodes" />
  </div>
</template>

<script>
import OrgChart from '@balkangraph/orgchart.js'
import AddBehaviourModal from '@/components/Behaviours/Modals/AddBehaviour.vue'
import { mapGetters, mapState } from 'vuex'
import axiosIns from '@/libs/axios'
import EditBehaviour from '@/components/Behaviours/Modals/EditBehaviour.vue'
import InterfaceSelect from '@/components/Domain/Modals/Interfaces/InterfaceSelect.vue'
import FunctionSelectModal from '@/components/Domain/Modals/FunctionSelectModal.vue'
import InstantiateFunctionBehaviour from '@/components/Behaviours/Modals/InstantiateFunctionBehaviour.vue'
import DeleteBehaviour from '@/components/Behaviours/Modals/DeleteBehaviour.vue'
import CopyBehaviour from '@/components/Behaviours/Modals/CopyBehaviour.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditTimingConstraint from '@/components/Behaviours/Modals/EditTimingConstraint.vue'
import ShowTestsModal from '@/components/Behaviours/Modals/showTests.vue'
import ViewRequirementModal from '@/components/Behaviours/Modals/ViewRequirementModal.vue'

export default {
  name: 'BehaviourTreeViewer',
  components: {
    AddBehaviourModal,
    EditBehaviour,
    DeleteBehaviour,
    CopyBehaviour,
    InterfaceSelect,
    FunctionSelectModal,
    InstantiateFunctionBehaviour,
    EditTimingConstraint,
    ViewRequirementModal,
    ShowTestsModal,
  },
  props: {
    refreshVariable: {
      type: Number,
      default: 0,
    },
    showLinks: {
      type: Boolean,
      default: false,
    },
    showTiming: {
      type: Boolean,
      default: false,
    },
    updateObject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chart: false,
      dragNode: '',
      dropNode: '',
      selParent: '',
      selNode: '',
      selectedNodeIds: [],
      nodes: [],
      newNode: {},
      layoutIcon: '',
      nodeMap: {},
      focusNode: '',
      colourBy: 'validity',
      testedness: {},
      copying: false,
      integrating: false,
      moving: false,
      testgen: false,
      instFnPerformers: [],
      instFnName: '',
      instFnReqs: [],
      instFnIss: [],
      passPercentage: 0,
      failPercentage: 0,
      noRunPercentage: 0,
      partialPercentage: 0,
      naPercentage: 0,
    }
  },
  computed: {
    ...mapState({
      selectedBT: state => state.behaviours.selectedBehaviourTree,
      selectedNode: state => state.behaviours.selectedBehaviourNode,
    }),
    ...mapGetters({
      behaviourOperators: 'constants/behaviourOperators',
    }),
  },
  watch: {
    selectedBT(newVal) {
      console.debug('[BehaviourTreeViewer] Tree changed: ', newVal)
      setTimeout(() => {
        this.getTreeData()
        this.oc(this.$refs.tree, this.nodes)
      }, 800)
    },
    focusNode(newVal) {
      console.log('Focus: ', newVal)
      this.chart.center(newVal, {}, () => {
        const focusedElements = document.querySelectorAll('.focused')
        for (let i = 0; i < focusedElements.length; i++) {
          focusedElements[i].classList.remove('focused')
        }
        const nodeElement = this.chart.getNodeElement(newVal)
        nodeElement.classList.add('focused')
      })
    },
    refreshVariable() {
      this.refresh()
    },
    showLinks() {
      console.log('Refs: ', this.selectedBT)
      if (this.showLinks) {
        if (this.selectedBT.references) {
          this.selectedBT.references.forEach(ref => {
            if (ref.rel_type === 'REVERT' || ref.rel_type === 'REVERSION') {
              this.chart.addClink(ref.source, ref.target, '', 'blue').draw(OrgChart.action.update)
            } else {
              this.chart.addSlink(ref.source, ref.target, '', 'blue').draw(OrgChart.action.update)
            }
          })
        }
      } else {
        this.refresh()
      }
    },
    showTiming() {
      console.log('Timing: ', this.selectedBT)
      if (this.showTiming) {
        if (this.selectedBT.timing) {
          this.selectedBT.timing.forEach(ref => {
            console.log('Timing2: ', ref.rel_props.timing_string)
            const label = ref.rel_props.timing_string
            this.chart.addSlink(ref.source, ref.target, label, 'orange').draw(OrgChart.action.update)
          })
        }
      } else {
        this.refresh()
      }
    },
    updateObject() {
      console.log('Updating')
      this.postUpdate()
    },
  },
  mounted() {
    OrgChart.templates.bt_fn_node = { ...OrgChart.templates.ana }
    OrgChart.templates.bt_fn_node.size = [300, 80]
    OrgChart.templates.bt_fn_node.node = '<rect fill="#FFFFF5" x="0" y="0" width="300" height="80" rx="2" ry="2" style="stroke: dimgrey;stroke-width: 2;"></rect>'
    OrgChart.templates.bt_fn_node.component = '<text text-overflow="multiline" width="260"  style="font-size: 1em;" x="140" y="35" dominant-baseline="middle" text-anchor="middle">{val}</text>'
    OrgChart.templates.bt_fn_node.nodeMenuButton = '<g style="cursor:pointer;" transform="matrix(1,0,0,1,275,70)" control-node-menu-id="{id}">'
                                            + '<rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect>'
                                            + '<circle cx="0" cy="0" r="2" fill="#696969"></circle><circle cx="7" cy="0" r="2" fill="#696969"></circle><circle cx="14" cy="0" r="2" fill="#696969"></circle></g>'

    OrgChart.templates.bt = { ...OrgChart.templates.ana }
    OrgChart.templates.bt.size = [300, 120]
    OrgChart.templates.bt.defs = '<marker id="arrow" viewBox="0 0 10 10" refX="8" refY="5" markerWidth="8" markerHeight="8" orient="auto-start-reverse"><path fill="dimgrey" d="M 0 0 L 10 5 L 0 10 z" /></marker><marker id="dotBlue" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="5" markerHeight="5"> <circle cx="5" cy="5" r="5" fill="#039BE5" /></marker>'
    OrgChart.templates.bt.link = '<path marker-start="url(#dotBlue)" marker-end="url(#arrow)"   stroke-linejoin="round" stroke="dimgrey" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />'
    OrgChart.templates.bt.node = '<rect fill="#FFFFFF" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt.component = '<g><text text-overflow="ellipsis" width="270"  style="font-size: 0.9em;" x="145" y="20" dominant-baseline="middle" text-anchor="middle">{val}</text><line x1="0" y1="30" x2="300" y2="30" stroke="dimgrey" stroke-width="0.5px"/></g>'
    OrgChart.templates.bt.start_symbol = '<text text-overflow="ellipsis" width="40"  style="font-size: 1.5em;"  x="25" y="50" dominant-baseline="middle" text-anchor="middle">{val}</text>'
    OrgChart.templates.bt.name = '<foreignObject x="43" y="40" width="214" height="70">'
                                  + '<div style="width: 214px; height: 70px; font-size: 0.9em; color: black!important; overflow: auto; text-align: center;">'
                                  + '{val}'
                                  + '</div></foreignObject>'
    OrgChart.templates.bt.end_symbol = '<text text-overflow="ellipsis" width="40"  style="font-size: 1.5em;"  x="275" y="50" dominant-baseline="middle" text-anchor="middle">{val}</text>'

    OrgChart.templates.bt.nodeMenuButton = '<g style="cursor:pointer;" transform="matrix(1,0,0,1,275,110)" control-node-menu-id="{id}">'
                                            + '<rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect>'
                                            + '<circle cx="0" cy="0" r="2" fill="#696969"></circle><circle cx="7" cy="0" r="2" fill="#696969"></circle><circle cx="14" cy="0" r="2" fill="#696969"></circle></g>'
    OrgChart.templates.bt.nodeCircleMenuButton = {
      radius: 14,
      x: 302,
      y: 60,
      color: '#fff',
      stroke: '#696969',
    }
    OrgChart.templates.bt.operator = '<text text-overflow="ellipsis" width="70"  style="font-size: 1em;" x="280" y="15" dominant-baseline="left" text-anchor="left">{val}</text>'

    OrgChart.templates.bt_green = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_green.node = '<rect fill="#D8E9D2" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_yellow = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_yellow.node = '<rect fill="#FFF2CB" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_amber = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_amber.node = '<rect fill="#FFE497" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_red = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_red.node = '<rect fill="#F3CBCB" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_white = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_white.node = '<rect fill="#FFFFFF" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_grey = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_grey.node = '<rect fill="#D8D8D8" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_violet = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_violet.node = '<rect fill="#D0BDF0" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_highlight = { ...OrgChart.templates.bt }
    OrgChart.templates.bt_highlight.node = '<rect fill="#16FFFF" x="0" y="0" width="300" height="120" rx="4" ry="4" style="stroke: #0080FF;stroke-width: 1;"></rect>'

    // Expansion
    OrgChart.templates.bt_details = { ...OrgChart.templates.ana }
    OrgChart.templates.bt_details.node = '<rect fill="#E6F0F2" x="0" y="0" width="800" height="400"></rect>'
    OrgChart.templates.bt_details.node += '<rect fill="#fff" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_details.node += '<rect fill="#fff" x="320" y="10" width="470" height="120"></rect>'
    OrgChart.templates.bt_details.node += '<rect fill="#fff" x="10" y="140" width="780" height="120"></rect>'
    OrgChart.templates.bt_details.node += '<text style="font-size: 14px;font-weight:bold;" fill="#676464" x="20" y="155">Requirements</text>'
    OrgChart.templates.bt_details.node += '<rect fill="#fff" x="10" y="270" width="385" height="120"></rect>'
    OrgChart.templates.bt_details.node += '<text style="font-size: 14px;font-weight:bold;" fill="#676464" x="20" y="285">Issues</text>'
    OrgChart.templates.bt_details.node += '<rect fill="#fff" x="405" y="270" width="385" height="120"></rect>'
    OrgChart.templates.bt_details.node += '<text style="font-size: 14px;font-weight:bold;" fill="#676464" x="415" y="285">Tests</text>'
    OrgChart.templates.bt_details.size = [800, 400]

    OrgChart.templates.bt_details.link = '<path marker-start="url(#dotBlue)" marker-end="url(#arrow)"   stroke-linejoin="round" stroke="dimgrey" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />'
    OrgChart.templates.bt_details.component = '<g><text text-overflow="ellipsis" width="270"  style="font-size: 0.9em;" x="155" y="20" dominant-baseline="middle" text-anchor="middle">{val}</text><line x1="10" y1="30" x2="310" y2="30" stroke="dimgrey" stroke-width="0.5px"/></g>'
    OrgChart.templates.bt_details.start_symbol = '<text text-overflow="ellipsis" width="40"  style="font-size: 1.5em;"  x="35" y="50" dominant-baseline="middle" text-anchor="middle">{val}</text>'
    OrgChart.templates.bt_details.name = '<foreignObject x="53" y="40" width="214" height="70">'
                                  + '<div style="width: 214px; height: 70px; font-size: 0.9em; color: black!important; overflow: auto; text-align: center;">'
                                  + '{val}'
                                  + '</div></foreignObject>'
    OrgChart.templates.bt_details.end_symbol = '<text text-overflow="ellipsis" width="40"  style="font-size: 1.5em;"  x="285" y="50" dominant-baseline="middle" text-anchor="middle">{val}</text>'
    OrgChart.templates.bt_details.operator = '<text text-overflow="ellipsis" width="70"  style="font-size: 1em;" x="290" y="15" dominant-baseline="left" text-anchor="left">{val}</text>'

    OrgChart.templates.bt_details.requirements = '<foreignObject x="20" y="170" width="760" height="90">'
                                  + '<div style="width: 760px; height: 90px; font-size: 0.8em; color: #676464!important; overflow: auto; text-align: left;">'
                                  + '{val}'
                                  + '</div></foreignObject>'
    OrgChart.templates.bt_details.issues = '<foreignObject x="20" y="300" width="365" height="90">'
                                  + '<div style="width: 365px; height: 90px; font-size: 0.8em; color: #676464!important; overflow: auto; text-align: left;">'
                                  + '{val}'
                                  + '</div></foreignObject>'
    OrgChart.templates.bt_details.tests = '<foreignObject x="415" y="300" width="365" height="90">'
                                  + '<div style="width: 365px; height: 90px; font-size: 0.8em; color: #676464!important; overflow: auto; text-align: left;">'
                                  + '{val}'
                                  + '</div></foreignObject>'

    OrgChart.templates.bt_green_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_green_details.node += '<rect fill="#D8E9D2" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_yellow_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_yellow_details.node += '<rect fill="#FFF2CB" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_amber_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_amber_details.node += '<rect fill="#FFE497" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_red_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_red_details.node += '<rect fill="#F3CBCB" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_white_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_white_details.node += '<rect fill="#FFFFFF" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_grey_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_grey_details.node += '<rect fill="#D8D8D8" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_violet_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_violet_details.node += '<rect fill="#D0BDF0" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'
    OrgChart.templates.bt_highlight_details = { ...OrgChart.templates.bt_details }
    OrgChart.templates.bt_highlight_details.node += '<rect fill="#16FFFF" x="10" y="10" width="300" height="120" rx="4" ry="4" style="stroke: dimgrey;stroke-width: 0.5;"></rect>'

    /*
    OrgChart.templates.green_percent2.goalShort = '<text data-text-overflow="multiline" data-width="230"  style="font-size: 14px;" fill="#676464" x="17" y="55">{val}</text>';
    OrgChart.templates.green_percent2.photo = '<image preserveAspectRatio="xMaxYMax slice" xlink:href="{val}" x="10" y="140"  width="120" height="120"></image>';
    OrgChart.templates.green_percent2.designation = '<text text-anchor="end"  data-width="230"  style="font-size: 14px;" fill="#676464" x="380" y="250">{val}</text>';
    OrgChart.templates.green_percent2.goalOwner = '<text data-width="230"  style="font-size: 14px;" fill="#676464" x="150" y="180">{val}</text>';
    */

    this.layoutIcon = '<svg width="16" height="16" x="0px" y="0px" fill="#ccc" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="m397.800781 311.417969v-85.476563h-126.800781v-43.359375h30.03125v-90.0625h-90.0625v90.0625h30.03125v43.359375h-126.800781v85.476563h-30.699219v90.0625h90.0625v-90.0625h-29.363281v-55.476563h96.800781v55.476563h-30.03125v90.0625h90.0625v-90.0625h-30.03125v-55.476563h96.800781v55.476563h-29.363281v90.0625h90.0625v-90.0625zm-156.832031-188.898438h30.0625v30.0625h-30.0625zm-97.40625 248.960938h-30.0625v-30.0625h30.0625zm127.46875 0h-30.0625v-30.0625h30.0625zm127.46875 0h-30.0625v-30.0625h30.0625zm0 0" fill="#ccc"/></svg>'
    this.upIcon = '<svg width="16" height="16" version="1.1" id="ios7_x5F_arrows_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve"><style>.st0{display:none}.st1{display:inline}</style><g id="_x34__1_"><path d="M64.1 0C28.8 0 .2 28.7.2 64s28.6 64 63.9 64S128 99.3 128 64c-.1-35.3-28.7-64-63.9-64zm0 122.7C31.7 122.7 5.5 96.4 5.5 64c0-32.4 26.2-58.7 58.6-58.7 32.3 0 58.6 26.3 58.6 58.7-.1 32.4-26.3 58.7-58.6 58.7zm-.3-93.9L33.1 59.5l3.8 3.8 24.5-24.5V104h5.3V39.4l24 24 3.8-3.8-30.7-30.8z"  fill="#ccc" icon_35_"/></g></svg>'
    this.homeIcon = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2"><path d="M55.579 31.579a2 2 0 0 1 .208 2.583l-1.284 1.781a1.996 1.996 0 0 1-3.036.245A462554.14 462554.14 0 0 1 32 16.722L12.533 36.188a1.996 1.996 0 0 1-3.036-.245l-1.284-1.781a2 2 0 0 1 .208-2.583L32 8l23.579 23.579z" style="fill:none;stroke:#222a33;stroke-width:2px"/><path d="M13.977 34.745 32 16.722l18.023 18.023v20.002a2.25 2.25 0 0 1-.66 1.593 2.25 2.25 0 0 1-1.593.66H16.23a2.25 2.25 0 0 1-1.593-.66 2.25 2.25 0 0 1-.66-1.593V34.745zM20.736 19.264l-7.885 7.885V15.322h7.885v3.942z" style="fill:none;stroke:#222a33;stroke-width:2px"/><path d="M37 44.5a1.503 1.503 0 0 0-1.5-1.5h-7a1.503 1.503 0 0 0-1.5 1.5V57h10V44.5z" style="fill:none;stroke:#7a7a7a;stroke-width:1px"/></svg>'
    this.mergeIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 24 24"><path d="m5 14 4-4-4-4v2H0v4h5v2zM19 6l-4 4 4 4v-2h5V8h-5V6zM11 0h2v20h-2z"/></svg>'
    this.moveIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 24 24"><path d="M18.364 8.465 16.95 9.879 18.071 11H13V5.928l1.122 1.122 1.414-1.414L12 2.101 8.464 5.636 9.878 7.05 11 5.928V11H5.929L7.05 9.879 5.636 8.465 2.1 12l3.536 3.535 1.414-1.414L5.929 13H11v5.072L9.878 16.95l-1.414 1.414L12 21.899l3.536-3.535-1.414-1.414L13 18.072V13h5.071l-1.121 1.121 1.414 1.414L21.9 12l-3.536-3.535z"/></svg>'
    this.copyIcon = '<svg  width="16" height="16" fill="#ccc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 10v10a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zM6 14H5V5h9v1a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2z"/></svg>'
    this.ifIcon = '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve"><style>.st0{display:none}.st1{display:inline}</style><g id="_x33_4_1_"><path d="M33.8 53.3 30 49.5-.1 79.7 30 109.9l3.8-3.8L10 82.3h63.2v-5.2H10l23.8-23.8zm94.1-5.1L97.8 18.1 94 21.9l23.8 23.8h-63v5.2h63L94.1 74.8l3.8 3.8L128 48.5v-.3h-.1z" id="icon_8_"/></g></svg>'
    this.exportIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="#ccc"><path d="M256 73.82a182.18 182.18 0 1 0 0 364.36c100.608 0 182.18-81.571 182.18-182.18A182.183 182.183 0 0 0 256 73.82zm-47.408 98.965 38.522-38.514a12.802 12.802 0 0 1 9.106-3.77h.193a12.854 12.854 0 0 1 9.122 3.77l38.506 38.514a12.896 12.896 0 1 1-18.237 18.237l-17.016-17.024v69.1a12.902 12.902 0 1 1-25.805 0v-68.22l-16.146 16.144a12.899 12.899 0 1 1-18.245-18.237zm146.487 155.259c0 17.411-17.077 31.051-38.883 31.051H195.803c-21.797 0-38.873-13.64-38.873-31.051V244.23c0-17.41 17.076-31.052 38.873-31.052h16.26a32.549 32.549 0 0 0 5.66.528 32.203 32.203 0 0 0 5.617-.528h.747v25.199h-28.283c-8.825 0-13.676 4.394-13.676 5.853v83.813c0 1.459 4.851 5.844 13.676 5.844h120.4c8.825 0 13.677-4.393 13.677-5.844V244.23c0-1.459-4.852-5.853-13.676-5.853h-28.52v-25.199h1.74a29.75 29.75 0 0 0 11.153 0h15.627c21.797 0 38.875 13.642 38.875 31.052v83.813z" data-name="File Export"/></svg>'
    this.testIcon = '<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 4v14a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V4h4z" stroke="#ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 14h4" stroke="#ccc" stroke-width="2"/><path d="M3 4h18M10 4v14a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V4h4z" stroke="#ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 8h4" stroke="#ccc" stroke-width="2"/></svg>'
    this.tickIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 24 24"><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z"/></svg>'
    this.fnIcon = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve"  width="16" height="16"  style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd" viewBox="0 0 6.827 6.827"><defs><style>.fil0{fill:#212121;fill-rule:nonzero}</style></defs><g id="Layer_x0020_1"><g id="_267713216"><path id="_267712808" class="fil0" d="M3.413 1.84a.419.419 0 0 1 .297.718.419.419 0 0 1-.717-.297.419.419 0 0 1 .42-.42zm.147.274a.206.206 0 0 0-.354.147.206.206 0 0 0 .354.146.206.206 0 0 0 0-.293z"/><path id="_267712976" class="fil0" d="M3.37 5.03v.837h-.213V5.03z"/><path id="_267713120" class="fil0" d="M3.632 5.03v.837h-.213V5.03z"/><path id="_267713456" class="fil0" d="M3.336 4.767h.155a9.677 9.677 0 0 0 .131-.349l.043-.12.11.063.496.286.03-.306-.46-.422-.048-.044.016-.061c.169-.647.197-1.18.108-1.634a2.337 2.337 0 0 0-.504-1.057c-.25.306-.424.65-.504 1.057-.088.453-.06.987.109 1.634l.016.061-.048.044-.46.422.03.306.495-.286.11-.064.044.121a9.386 9.386 0 0 0 .13.35zm.227.213h-.37l-.028-.066a10.586 10.586 0 0 1-.118-.305l-.527.305-.143.082-.016-.164-.052-.523-.006-.052.04-.036.453-.416C2.633 3.153 2.609 2.61 2.7 2.14c.095-.49.315-.894.634-1.25l.08-.09.078.09c.32.356.54.76.635 1.25.09.469.067 1.013-.096 1.665l.453.416.04.036-.006.052-.052.523-.017.164-.142-.082-.528-.305a10.396 10.396 0 0 1-.117.305l-.028.066h-.071z"/></g></g><path style="fill:none" d="M0 0h6.827v6.827H0z"/></svg>'
    this.cogIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" viewBox="0 0 512 512"><g data-name="Setting Cog"><path d="M256 229.123a34.247 34.247 0 1 1-34.251 34.251A34.246 34.246 0 0 1 256 229.123z"/><path d="M256 73.82A182.18 182.18 0 1 0 438.18 256 182.182 182.182 0 0 0 256 73.82zm95.73 208.653-25.875 1.67a72.5 72.5 0 0 1-5.765 13.94l17.112 19.485-27.009 27.017-19.486-17.12a71.936 71.936 0 0 1-13.939 5.765l-1.67 25.875h-38.205l-1.68-25.875a72.325 72.325 0 0 1-13.93-5.765l-19.476 17.12-27.01-27.026 17.104-19.477a71.904 71.904 0 0 1-5.756-13.939l-25.884-1.678v-38.199l25.884-1.67a72.478 72.478 0 0 1 5.756-13.939l-17.103-19.467 27.009-27.027 19.476 17.113a72.14 72.14 0 0 1 13.94-5.757l1.67-25.884H275.1l1.67 25.884a71.907 71.907 0 0 1 13.939 5.757l19.486-17.113 27.009 27.027-17.113 19.468a72.147 72.147 0 0 1 5.765 13.939l25.875 1.67z"/></g></svg>'
    this.rightIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><g data-name="19-Arrow Right"><path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"/><path d="m26.71 15.29-7-7-1.42 1.42 5.3 5.29H5v2h18.59l-5.29 5.29 1.41 1.41 7-7a1 1 0 0 0 0-1.41z"/></g></svg>'
    this.revertIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><path d="M12 3a8.959 8.959 0 0 0-7 3.339V4H3v6h6V8H6.274a6.982 6.982 0 1 1-1.054 5.751l-1.936.5A9 9 0 1 0 12 3z"/></svg>'
    this.refineIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><path d="M12 3a8.959 8.959 0 0 0-7 3.339V4H3v6h6V8H6.274a6.982 6.982 0 1 1-1.054 5.751l-1.936.5A9 9 0 1 0 12 3z"/></svg>'
    this.reqIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ccc" viewBox="0 0 32 32"><g data-name="33-Notice"><path d="M25 0H7a7 7 0 0 0-7 7v18a7 7 0 0 0 7 7h18a7 7 0 0 0 7-7V7a7 7 0 0 0-7-7zm5 25a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h18a5 5 0 0 1 5 5z"/><path d="M16 5a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V8a3 3 0 0 0-3-3zm1 11a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0zM16 21a3 3 0 0 0-2.79 1.89 1 1 0 0 0-.21.61v.5a3 3 0 1 0 3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"/></g></svg>'
    this.clockIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>'
    this.colourBy = this.$route.params.colourBy || 'validity'
    console.debug('[BehaviourTreeViewer] Selected BT on mount: ', this.selectedBT)
    setTimeout(() => {
      this.getTreeData()
      this.oc(this.$refs.tree, this.nodes)
    }, 800)
  },
  methods: {
    oc(domEl, x) {
      const vueApp = this
      this.chart = new OrgChart(domEl, {
        template: 'bt',
        enableDragDrop: false,
        scaleInitial: 0.9,
        siblingSeparation: 30,
        miniMap: false,
        mouseScrool: OrgChart.action.scroll,
        showYScroll: OrgChart.scroll.visible,
        showXScroll: OrgChart.scroll.visible,
        nodeMouseClick: OrgChart.action.none,
        nodes: x,
        menu: {
          tst: {
            icon: vueApp.exportIcon,
            text: 'Generate Tests from View',
            onClick() {
              console.log('Generate Tests from Behaviour Tree: ', vueApp.selectedBT.id)
              // TODO Open modal to configure test generation (criticality, ...)
              vueApp.testgen = true
              const modelId = vueApp.$store.state.model.id
              vueApp.$http.get(`/api/v2/behaviour/generate_tests/${vueApp.selectedBT.id}`, { params: { model: modelId } })
                .then(({ data }) => {
                  vueApp.testgen = false
                  vueApp.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Test Generation Complete',
                      icon: 'CheckIcon',
                      text: `${data.tests} generated in ${data.time} seconds`,
                      variant: 'success',
                    },
                  })
                })
            },
          },
          cbv: {
            icon: vueApp.tickIcon,
            text: 'Colour - Validity',
            onClick() {
              console.log('Colour by Validity: ', vueApp.selectedBT.id)
              vueApp.colourBy = 'validity'
              vueApp.refresh()
              vueApp.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Showing Validity',
                  text: 'Behaviour view coloured by validity',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            },
          },
          cbt: {
            icon: vueApp.testIcon,
            text: 'Colour - Test Results',
            onClick() {
              console.log('Colour by Test Results: ', vueApp.selectedBT.id)
              vueApp.$bvModal.show('show-tests-modal')
            },
          },
          cbr: {
            // TODO:  Update this
            icon: vueApp.reqIcon,
            text: 'Highlight Requirement Behaviours',
            onClick() {
              vueApp.$bvModal.show('view-requirement-modal')
            },
          },
          csv: { text: 'Save as CSV' },
          xml: { text: 'Export UML XMI' },

        },
        nodeBinding: {
          component: 'display_cpt_name',
          start_symbol: 'symbol_l',
          end_symbol: 'symbol_r',
          name: 'display_name',
          operator: 'op_str',
          requirements: 'requirement_str',
          issues: 'issue_str',
          tests: 'test_str',
        },
        // align: OrgChart.ORIENTATION,
        toolbar: {
          fullScreen: true,
          zoom: true,
          fit: true,
          expandAll: true,
        },
        tags: {
          function_node: {
            template: 'bt_fn_node',
          },
          bt_green: {
            template: 'bt_green',
          },
          bt_yellow: {
            template: 'bt_yellow',
          },
          bt_amber: {
            template: 'bt_amber',
          },
          bt_red: {
            template: 'bt_red',
          },
          bt_white: {
            template: 'bt_white',
          },
          bt_grey: {
            template: 'bt_grey',
          },
          bt_violet: {
            template: 'bt_violet',
          },
          bt_highlight: {
            template: 'bt_highlight',
          },
          bt_details: {
            template: 'bt_details',
          },
          bt_green_details: {
            template: 'bt_green_details',
          },
          bt_yellow_details: {
            template: 'bt_yellow_details',
          },
          bt_amber_details: {
            template: 'bt_amber_details',
          },
          bt_red_details: {
            template: 'bt_red_details',
          },
          bt_white_details: {
            template: 'bt_white_details',
          },
          bt_grey_details: {
            template: 'bt_grey_details',
          },
          bt_violet_details: {
            template: 'bt_violet_details',
          },
          bt_highlight_details: {
            template: 'bt_highlight_details',
          },
        },
      })
      vueApp.chart.on('key-down', (sender, args) => {
        if (args.node) {
          if (args.event.code === 'Enter' || args.event.code === 'NumpadEnter') {
            const nodeElement = sender.getNodeElement(args.node.id)
            // Expand Node
            const focusedElements = document.querySelectorAll('.focused')
            for (let i = 0; i < focusedElements.length; i++) {
              focusedElements[i].classList.remove('focused')
              focusedElements[i].classList.remove('animate-pulse')
            }
            nodeElement.classList.add('focused')
            nodeElement.classList.add('animate-pulse')
            vueApp.selectedNodeIds = []
            if (vueApp.nodeMap[args.node.id].expanded) {
              vueApp.nodeMap[args.node.id].tags = [vueApp.nodeMap[args.node.id].base_tag]
              args.node.tags = [vueApp.nodeMap[args.node.id].base_tag]
              vueApp.chart.draw(OrgChart.action.update)
              vueApp.nodeMap[args.node.id].expanded = false
            } else {
              const tName = `${vueApp.nodeMap[args.node.id].base_tag}_details`
              console.log('New Tag: ', tName)
              vueApp.nodeMap[args.node.id].tags = [tName]
              args.node.tags = [tName]
              vueApp.chart.draw(OrgChart.action.update)
              vueApp.nodeMap[args.node.id].expanded = true
            }
          } else if (args.event.code === 'KeyD') {
            const focusedElements = document.querySelectorAll('.focused')
            for (let i = 0; i < focusedElements.length; i++) {
              focusedElements[i].classList.remove('focused')
              focusedElements[i].classList.remove('animate-pulse')
            }
            const nodeElement = sender.getNodeElement(args.node.id)
            nodeElement.classList.add('focused')
            nodeElement.classList.add('animate-pulse')
            vueApp.selectedNodeIds = []
            vueApp.$emit('clickNode', args.node.id)
          } else if (args.event.code === 'KeyS') {
            sender.exportSVG({
              filename: 'OrgChart.svg',
              expandChildren: false,
              nodeId: args.node.id,
            })
          } else if (args.event.code === 'KeyC') {
            sender.exportCSV()
          } else if (args.event.code === 'KeyX') {
            sender.exportXML()
          }
        }
      })
      /*
      vueApp.chart.on('click', (sender, args) => {
        if (args.event.ctrlKey || args.event.metaKey) {
          const nodeElement = sender.getNodeElement(args.node.id)
          if (nodeElement.classList.contains('focused')) {
            nodeElement.classList.remove('focused')
            const index = this.selectedNodeIds.indexOf(args.node.id)
            if (index > -1) {
              this.selectedNodeIds.splice(index, 1)
            }
          } else {
            nodeElement.classList.add('focused')
            this.selectedNodeIds.push(args.node.id)
          }
        } else {
          const focusedElements = document.querySelectorAll('.focused')
          for (let i = 0; i < focusedElements.length; i++) {
            focusedElements[i].classList.remove('focused')
            focusedElements[i].classList.remove('animate-pulse')
          }
          const nodeElement = sender.getNodeElement(args.node.id)
          nodeElement.classList.add('focused')
          nodeElement.classList.add('animate-pulse')
          this.selectedNodeIds = []
          // this.$emit('clickNode', args.node.id)
          // Expand Node
          console.log('Expand: ', args.node, this.nodeMap[args.node.id])
          if (this.nodeMap[args.node.id].expanded) {
            this.nodeMap[args.node.id].tags = ['bt_green']
            args.node.tags = ['bt_green']
            vueApp.chart.draw(OrgChart.action.update)
            this.nodeMap[args.node.id].expanded = false
          } else {
            this.nodeMap[args.node.id].tags = ['bt_details']
            args.node.tags = ['bt_details']
            vueApp.chart.draw(OrgChart.action.update)
            this.nodeMap[args.node.id].expanded = true
          }
        }
        return false
      })
      */
      vueApp.chart.on('searchclick', (sender, nodeId) => {
        vueApp.chart.center(nodeId, {}, () => {
          const focusedElements = document.querySelectorAll('.focused')
          for (let i = 0; i < focusedElements.length; i++) {
            focusedElements[i].classList.remove('focused')
            focusedElements[i].classList.remove('animate-pulse')
          }
          const nodeElement = vueApp.chart.getNodeElement(nodeId)
          nodeElement.classList.add('focused')
          nodeElement.classList.add('animate-pulse')
          // vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        })
        return false
      })
      // eslint-disable-next-line new-cap
      const ddMenu = new OrgChart.menuUI()
      ddMenu.init(vueApp.chart, {
        dismiss: {
          icon: '',
          // icon: OrgChart.icon.close(16, 16, '#ccc'),
          onClick() {
            return null
          },
          text: 'Dismiss menu',
        },
        integrate: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Integrate')
            vueApp.integrating = true
            vueApp.integrate()
          },
          text: 'Integrate',
        },
        moveSeq: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Move After')
            vueApp.moving = true
            vueApp.integrate()
          },
          text: 'Move After',
        },
        copyChildren: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Copy With Children')
            vueApp.copying = true
            vueApp.copyNode(true)
          },
          text: 'Copy After (with children)',
        },
        copyNoChildren: {
          icon: '',
          onClick() {
            console.debug('[BehaviourTreeViewer] Copy Without Children')
            vueApp.copying = true
            vueApp.copyNode(false)
          },
          text: 'Copy After (without children)',
        },
      })

      vueApp.chart.on('drop', (sender, draggedNodeId, droppedNodeId) => {
        if (sender._canUpdateLink(draggedNodeId, droppedNodeId)) {
          vueApp.dragNode = draggedNodeId
          vueApp.dropNode = droppedNodeId

          const rect = sender.getNodeElement(droppedNodeId).getBoundingClientRect()
          console.debug('[BehaviourTreeViewer] Drop rect: ', rect)
          ddMenu.show(rect.left, rect.top)
        }
        return false
      })

      vueApp.chart.nodeCircleMenuUI.on('drop', (sender, args) => {
        console.log('Drop circle menu: ', sender, args)
        if (args.menuItemName === 'time') {
          vueApp.selectedNodeIds = [args.from, args.to]
          vueApp.editTiming()
        } else {
          const params = {
            source: args.from,
            target: args.to,
            bt: vueApp.selectedBT.id,
            relationship: args.menuItemName,
            model: vueApp.$store.state.model.id,
          }
          const source = vueApp.chart.getNode(args.from)
          source.op_str = ''
          const op = vueApp.behaviourOperators.find(e => e.id === args.menuItemName)
          if (op) {
            source.op_str = op.display
            const node = vueApp.chart.get(args.from)
            node.op_str = op.display
            vueApp.chart.updateNode(node)
          }
          axiosIns.post('/api/v2/behaviour/create_bn_relationship', params).then(() => {
            if (args.menuItemName === 'reversion') {
              vueApp.chart.addClink(args.from, args.to, '', 'blue').draw(OrgChart.action.update)
            } else if (args.menuItemName === 'start_new') {
              vueApp.chart.addSlink(args.from, args.to, '', 'blue').draw(OrgChart.action.update)
            } else if (args.menuItemName === 'synchronise') {
              vueApp.chart.addSlink(args.from, args.to, '', 'orange').draw(OrgChart.action.update)
            } else {
              vueApp.chart.addSlink(args.from, args.to, '', 'blue').draw(OrgChart.action.update)
            }
          })
        }
      })
      this.focusOnNode()
    },
    focusOnNode() {
      const nodeId = this.$route.params.focus
      if (nodeId) {
        console.debug(`[BehaviourTreeViewer] Focusing on nodeId: ${nodeId}`)
        this.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        this.chart.center(nodeId, {
          vertical: true,
          horizontal: true,
        })
        const nodeElement = this.chart.getNodeElement(nodeId)
        nodeElement.classList.add('focused')
        nodeElement.classList.add('animate-pulse')
        this.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
      }
    },
    getNodeBits(x) {
      const vueApp = this
      x.tags = []

      const rootTag = 'bt'
      if (!vueApp.colourBy || vueApp.colourBy === '' || vueApp.colourBy === 'validity') {
        let t = `${rootTag}_white`
        if (x.validity === 'Valid') {
          t = `${rootTag}_green`
        } else if (x.validity === 'Minor Assumption') {
          t = `${rootTag}_yellow`
        } else if (x.validity === 'Implied') {
          t = `${rootTag}_amber`
        } else if (x.validity === 'Invalid') {
          t = `${rootTag}_red`
        }
        x.tags.push(t)
        x.base_tag = t

        const key = document.getElementById('tree-key')
        key.innerHTML = `
          <h4>Key - Coloured by Validity</h4>
          <h6>
            <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
              Valid
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
              Minor Uncertainty
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFE497; color: #2d2d2d">
              Moderate Uncertainty
            </b-badge>&nbsp;
            <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
              Major Uncertainty
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFFFFF; color: #2d2d2d">
              Refinement
            </b-badge>
          </h6>`
      } else if (vueApp.colourBy === 'testedness') {
        let t = `${rootTag}_grey`
        const tr = vueApp.testedness[x.id] || 'No Tests'
        if (tr === 'Passed') {
          t = `${rootTag}_green`
        } else if (tr === 'Failed') {
          t = `${rootTag}_red`
        } else if (tr === 'Inconclusive' || tr === 'No Run' || tr === 'Blocked' || tr === 'N/A') {
          t = `${rootTag}_yellow`
        } else if (tr === 'Partial') {
          t = `${rootTag}_violet`
        } else {
          t = `${rootTag}_grey`
        }
        x.tags.push(t)
        x.base_tag = t

        const key = document.getElementById('tree-key')
        key.innerHTML = `
          <h4>Key - Coloured by Test Execution Results</h4>
          <h6>
            <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
              Passed ${this.passPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
              Failed ${this.failPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D0BDF0; color: #2d2d2d">
              Partial ${this.partialPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
              Not Executed ${this.noRunPercentage}%
            </b-badge>&nbsp;
            <b-badge style="background-color: #D8D8D8; color: #2d2d2d">
              No Tests Defined ${this.naPercentage}%
            </b-badge>
          </h6>`
      }

      if (x.type === 'FunctionNode') {
        x.tags = ['function_node']
        x.base_tag = 'function_node'
      }
      // this needs to be a param or dynamically set thing
      if (x.highlight) {
        const ht = `${rootTag}_highlight`
        x.tags = [ht]
      }

      // Create a display name for the behaviour
      if (x.type === 'Quantification') {
        if (x.behaviour_name === '!-%') {
          x.display_name = `There does not Exist an ${x.display_cpt_name} in ${x.set}`
        } else if (x.behaviour_name === '?-%') {
          x.display_name = `If there does not Exist an ${x.display_cpt_name} in ${x.set}`
        } else if (x.behaviour_name === '!%') {
          x.display_name = `There is at least one ${x.display_cpt_name} in ${x.set}`
        } else if (x.behaviour_name === '?%') {
          x.display_name = `If there is at least one ${x.display_cpt_name} in ${x.set}`
        } else {
          x.display_name = `For each ${x.display_cpt_name} in ${x.set}`
        }
      } else if (x.type === 'Quantity') {
        x.display_name = `For up to ${x.quantity} of ${x.display_cpt_name} in ${x.set}`
      } else if (x.type === 'Input' || x.type === 'Output') {
        x.display_name = x.io_resource_name ? `<p>${x.io_resource_name}</p>` : `<p>${x.io_resource}</p>`
      } else {
        x.display_name = (x.negated && (x.negated === 'true' || x.negated === 'True' || x.negated === true)) ? `<p>NOT (<i>${x.behaviour_name})</i>` : `<p><i>${x.behaviour_name}</i>`
      }

      if (x.type !== 'Quantification') {
        if (x.type === 'Input' || x.type === 'Output') {
          // Create a display name for rel parts
          if (x.rel_parts && x.rel_parts.length > 0) {
            x.rel_parts.forEach(r => {
              x.display_name += `<p class="rel">${r.name}</p>`
            })
          }
        } else {
          x.rel_parts.forEach(r => {
            x.display_name += (r && r.preposition !== '') ? ` ${r.preposition}` : ''
            if (r.instance_name && r.instance_name !== '') {
              x.display_name += ` <b>${r.object_name}</b>#${r.instance_name}`
            } else {
              x.display_name += ` <b>${r.object_name}</b>`
            }
            if (r.attribute && r.attribute !== '') {
              x.display_name += `.${r.attribute}`
            }
          })
          x.display_name += '</p>'
        }
      }

      // create an operator string
      x.op_str = ''
      const op = this.behaviourOperators.find(e => e.id === x.operator)
      if (op) {
        x.op_str = op.display
      }

      const strLimit = 100
      const strLimitL = 180
      let requirementsStr = ''
      x.requirements.forEach(r => {
        if (requirementsStr !== '') {
          requirementsStr += '<br>'
        }
        let objText = r.object_text
        if (r.object_text.length > strLimitL) {
          objText = `${r.object_text.slice(0, strLimitL)}...`
        }
        requirementsStr += `<a href="#">${r.display_id}</a> [${r.priority}]: ${objText}`
      })
      x.requirement_str = requirementsStr

      let issueStr = ''
      x.issues.forEach(r => {
        if (issueStr !== '') {
          issueStr += '<br>'
        }
        let objText = r.name
        if (r.name.length > strLimit) {
          objText = `${r.name.slice(0, strLimit)}...`
        }
        if (r.classification !== 'Query' && r.classification !== 'Design Query') {
          issueStr += `<a href="#">${r.display_id}</a> [${r.classification}-${r.severity}]: ${objText}`
        } else {
          issueStr += `<a href="#">${r.display_id}</a> [${r.classification}]: ${objText}`
        }
      })
      x.issue_str = issueStr

      let testStr = ''
      x.tests.forEach(r => {
        if (testStr !== '') {
          testStr += '<br>'
        }
        let objText = r.name.replace(/</g, '').replace(/>/g, '')
        if (r.name.length > strLimit) {
          objText = `${r.name.replace(/</g, '').replace(/>/g, '').slice(0, strLimit)}...`
        }
        let resultStr = ''
        if (r.latest_result && r.latest_result === 'Passed') {
          resultStr = '<span style="color:green">Passed</span>'
        } else if (r.latest_result && r.latest_result === 'Failed') {
          resultStr = '<span style="color:red">Failed</span>'
        } else {
          resultStr = '<span style="color:grey">Not Executed</span>'
        }
        testStr += `<a href="#">${r.ref_id}</a> [${resultStr}] ${objText}`
      })
      x.test_str = testStr

      return x
    },
    getTreeData() {
      const vueApp = this
      const nn = []
      vueApp.selectedBT.nodes.forEach(x => {
        x = vueApp.getNodeBits(x)
        vueApp.nodeMap[x.id] = x

        if (x.is_root) {
          nn.push(x)
        }
      })

      const en = vueApp.selectedBT.edges
      en.forEach(e => {
        const n = vueApp.nodeMap[e.target]
        n.pid = e.source
        nn.push(n)
      })
      console.debug('[BehaviourTreeViewer] Test graph: ', nn)
      vueApp.nodes = nn
    },
    integrate() {
      const vueApp = this
      console.debug('Integrating ', this.dragNode, ' to ', this.dropNode)
      const node = vueApp.chart.get(this.dragNode)
      axiosIns.post('/api/v2/behaviour/integrate', {
        model: vueApp.$store.state.model.id,
        source: vueApp.dragNode,
        target: vueApp.dropNode,
        source_tree: vueApp.selectedBT.id,
        rel_type: 'sequence',
      }).then(({ data }) => {
        vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
          setTimeout(() => {
            vueApp.getTreeData()
            vueApp.oc(this.$refs.tree, this.nodes)
            vueApp.focusNode = vueApp.dropNode
            vueApp.integrating = false
            vueApp.moving = false
          }, 800)
        })
      })
    },
    instantiateInterface(data) {
      const vueApp = this
      axiosIns.post('/api/v2/behaviour/instantiate_interface', {
        interface: data,
        target: vueApp.selParent,
        bt: vueApp.selectedBT.id,
        parent_rel: 'sequence',
        model: vueApp.$store.state.model.id,
      }).then(({ data }) => {
        vueApp.$bvModal.hide('instantiateInterfaceModal')
        vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
          setTimeout(() => {
            console.debug('BT on refresh: ', this.composition_tree)
            vueApp.getTreeData()
            vueApp.oc(this.$refs.tree, this.nodes)
            vueApp.focusNode = data.length > 0 ? data[0] : vueApp.selParent
          }, 800)
        })
      })
    },
    instantiateFn1(data) {
      console.log('Selecting Function: ', data)
      const params = { model: this.$store.state.model.id }
      this.$http.get(`/api/v2/domain_model/entity/${data}`, { params })
        .then(response => {
          const fnCpt = response.data
          console.log('Selected Function: ', fnCpt)
          this.instFnPerformers = fnCpt.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.source)
          this.instFnName = fnCpt.context.details.name
          this.instFnReqs = fnCpt.context.relationships.filter(e => e.labels.includes('Requirement')).map(x => x.target_props.id)
          this.instFnIss = fnCpt.context.relationships.filter(e => e.labels.includes('Issue')).map(x => x.target_props.id)
          console.log('Open Instantiate Modal With: ', this.instFnPerformers, this.instFnName)
          this.$bvModal.hide('function-select-modal')
          this.$bvModal.show('instantiate-fn-modal')
        })
    },
    copyNode(withChildren = true) {
      const vueApp = this
      const fields = {
        source: vueApp.dragNode,
        target: vueApp.dropNode,
        bt: vueApp.selectedBT.id,
        rel_type: 'sequence',
        with_children: withChildren,
      }
      axiosIns.post('/api/v2/behaviour/copy_node', fields).then(({ data }) => {
        vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
          setTimeout(() => {
            vueApp.getTreeData()
            vueApp.oc(this.$refs.tree, this.nodes)
            vueApp.focusNode = vueApp.dropNode
            vueApp.copying = false
          }, 800)
        })
      })
    },
    postCopy(node) {
      console.debug('[BehaviourTreeViewer] Copy callback: ', node)
      this.dragNode = this.selected_entity2.context.details.id
      this.dropNode = node.selected_parent
      if (node.parent_rel === 'aggregation') {
        this.copyAggregate()
      } else {
        this.copySubType()
      }
    },
    showTests(data) {
      console.log('Testedness: ', data)
      this.colourBy = 'testedness'
      this.testedness = data
      let p = 0
      let f = 0
      let ne = 0
      let na = 0
      let part = 0
      Object.keys(data).forEach(key => {
        console.log(`${key} ${data[key]}`)
        if (data[key] === 'No Tests') {
          na++
        } else if (data[key] === 'Passed') {
          p++
        } else if (data[key] === 'Failed') {
          f++
        } else if (data[key] === 'Partial') {
          part++
        } else if (data[key] === 'Inconclusive' || data[key] === 'No Run' || data[key] === 'Blocked' || data[key] === 'N/A') {
          ne++
        } else {
          na++
        }
      })
      const total = p + f + ne + na + part + 0
      this.passPercentage = ((p * 100) / total).toFixed(1)
      this.failPercentage = ((f * 100) / total).toFixed(1)
      this.noRunPercentage = ((ne * 100) / total).toFixed(1)
      this.partialPercentage = ((part * 100) / total).toFixed(1)
      this.naPercentage = ((na * 100) / total).toFixed(1)
      this.refresh()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Showing Test Results',
          text: 'Behaviour view coloured by test results',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    addNode(nodeId) {
      const vueApp = this
      vueApp.selParent = nodeId
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('add-behaviour-modal')
        })
    },
    addPrecondition(nodeId) {
      const vueApp = this
      this.selNode = nodeId
      this.selParent = vueApp.chart.getNode(nodeId).pid
      console.log('[BehaviourTreeViewer] Adding Precondition after ', this.selParent, 'and before', this.selNode)
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('add-precond-modal')
        })
    },
    editNode(nodeId) {
      const vueApp = this
      this.selNode = nodeId
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('edit-behaviour-modal')
        })
    },
    editTiming() {
      const vueApp = this
      console.log('Selected: ', this.selectedNodeIds)
      if (this.selectedNodeIds.length <= 1) {
        // eslint-disable-next-line
        alert('To add performance timing, you must select at least a start and end node.')
      } else {
        vueApp.$bvModal.show('timed-bn-modal')
      }
    },
    deleteNode(nodeId) {
      const vueApp = this
      vueApp.selParent = vueApp.chart.getNode(nodeId).pid
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('delete-behaviour-modal')
        })
    },
    copyNodeDet(nodeId) {
      const vueApp = this
      vueApp.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          vueApp.$bvModal.show('copy-node-modal')
        })
    },
    refineBehaviour(nodeId) {
      const vueApp = this
      const fields = {
        bn: nodeId,
        bt: vueApp.selectedBT.id,
        model: vueApp.$store.state.model.id,
      }
      axiosIns.post('/api/v2/behaviour/refine_new', fields).then(({ data }) => {
        let node = vueApp.chart.getNode(nodeId)
        console.log('Update refined node details: ', node)
        const n = vueApp.nodeMap[nodeId]
        node = this.getNodeBits(n)
        node.operator = 'refined'
        vueApp.chart.updateNode(node)
        const nodeElement = vueApp.chart.getNodeElement(node.id)
        nodeElement.classList.add('focused')
        vueApp.focusNode = node.id
      })
    },
    selectBulk(nodeId, withSelf = true) {
      this.selectedNodeIds = []
      if (withSelf) {
        this.selectedNodeIds.push(nodeId)
      }
      // get behaviour node children and add to selected node ids
      this.$http
        .get(`/api/v2/behaviour/get_bn_descendents/${nodeId}/${this.selectedBT.id}`).then(({ data }) => {
          data.forEach(d => {
            this.selectedNodeIds.push(d.id)
          })
          // Highlight selected nodes
          const focusedElements = document.querySelectorAll('.focused')
          for (let i = 0; i < focusedElements.length; i++) {
            focusedElements[i].classList.remove('selectedfocus')
            focusedElements[i].classList.remove('focus')
          }
          if (this.selectedNodeIds.length > 0) {
            console.log('Highlighting selected: ', this.selectedNodeIds)
            this.selectedNodeIds.forEach(n => {
              const nodeElement = this.chart.getNodeElement(n)
              if (nodeElement) {
                nodeElement.classList.add('selectedfocus')
              }
            })
            this.$emit('bulkSelect', this.selectedNodeIds)
          }
        })
    },
    highlight_nodes(nodes) {
      // Highlight selected nodes
      console.log('Nodes to highlight: ', nodes)
      const focusedElements = document.querySelectorAll('.focused')
      for (let i = 0; i < focusedElements.length; i++) {
        focusedElements[i].classList.remove('selectedfocus')
        focusedElements[i].classList.remove('focus')
      }

      nodes.forEach(n => {
        const nodeElement = this.chart.getNodeElement(n.id)
        if (nodeElement) {
          nodeElement.classList.add('selectedfocus')
        }
      })
    },
    postAdd(data) {
      const vueApp = this
      let pid = vueApp.selParent
      data.nodes.forEach(n => {
        n.pid = pid
        const node = this.getNodeBits(n)
        vueApp.chart.addNode(node)
        pid = n.id
      })
      if (data.nodes.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        vueApp.selNode = data.nodes[0].id
      }
      vueApp.$bvModal.hide('add-behaviour-modal')
      vueApp.$bvModal.hide('add-precond-modal')
      vueApp.$bvModal.hide('instantiate-fn-modal')
      vueApp.focusNode = vueApp.selNode
    },
    postAddPrecond(data) {
      const vueApp = this
      // Connect up precond nodes to previous parent
      let pid = vueApp.selParent
      data.nodes.forEach(n => {
        n.pid = pid
        const node = this.getNodeBits(n)
        vueApp.chart.addNode(node)
        pid = n.id
      })

      // connect last node to the node we are adding precond to
      const node = vueApp.chart.get(vueApp.selNode)
      node.pid = pid
      console.log('Node to reassign: ', node)
      vueApp.chart.updateNode(node)

      // Set the selected node to the first precondition node
      if (data.nodes.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        vueApp.selNode = data.nodes[0].id
      }
      vueApp.$bvModal.hide('add-precond-modal')
      vueApp.focusNode = vueApp.selNode
    },

    postUpdate() {
      const vueApp = this
      vueApp.$bvModal.hide('edit-behaviour-modal')
      const nodeData = this.selectedNode.details
      let node = vueApp.chart.getNode(this.selectedNode.details.id)
      console.log('Update details: ', node)
      const { pid } = node
      node = this.getNodeBits(this.updateObject)
      node.pid = pid
      vueApp.chart.updateNode(node)
      const nodeElement = vueApp.chart.getNodeElement(node.id)
      nodeElement.classList.add('focused')
      vueApp.focusNode = node.id
    },
    postTiming(data) {
      console.log('PostTiming: ', data)
      const vueApp = this
      const nodeElement = vueApp.chart.getNodeElement(data.start)
      nodeElement.classList.add('focused')
      vueApp.focusNode = data.start
      this.chart.addSlink(data.start, data.end, data.label, 'orange').draw(OrgChart.action.update)
    },
    postDel(data) {
      const vueApp = this
      data.deleted.forEach(n => {
        vueApp.chart.removeNode(n)
      })
      vueApp.focusNode = vueApp.selParent
    },
    mergeNode(nodeId) {
      const vueApp = this
      const node = vueApp.chart.getNode(nodeId)
      console.debug('[BehaviourTreeViewer] Merging: ', node)
      vueApp.selNode = nodeId
      vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        .then(() => {
          vueApp.$bvModal.show('merge-entity-modal')
        })
    },
    postMerge(node) {
      const vueApp = this
      const nodeId = node.focus
      vueApp.chart.removeNode(vueApp.selNode)
      vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        .then(() => {
          vueApp.chart.center(nodeId, {}, () => {
            const focusedElements = document.querySelectorAll('.focused')
            for (let i = 0; i < focusedElements.length; i++) {
              focusedElements[i].classList.remove('focused')
            }
            const nodeElement = vueApp.chart.getNodeElement(nodeId)
            nodeElement.classList.add('focused')
          })
        })
    },
    allocate(nodeId) {
      const vueApp = this
      vueApp.$store.dispatch('domainModel/selectEntity2', nodeId)
        .then(() => {
          console.debug('[BehaviourTreeViewer] Allocating ', nodeId)
          vueApp.$bvModal.show('allocate-function-modal')
        })
    },
    refresh() {
      const vueApp = this
      vueApp.$store.dispatch('behaviours/selectBehaviourTree', vueApp.selectedBT.id).then(() => {
        setTimeout(() => {
          vueApp.getTreeData()
          vueApp.oc(this.$refs.tree, this.nodes)
          vueApp.focusNode = vueApp.selParent
        }, 800)
      })
    },
    routeReference() {
      if (this.selectedNode.refinements && this.selectedNode.refinements.length > 0) {
        const btN = this.selectedNode.refinements[0].bt.id
        this.$store.dispatch('behaviours/selectBehaviourTree', btN)
      } else {
        console.error('Do something with references and reversions')
      }
    },
  },
  template: false,
}
</script>

<style>
#tree{
  display: block;
  height: 71vh !important; /* FIXME:*/
  overflow-y: scroll;
}
.focused rect{
  fill: #42A5F5;
  stroke-width:2;
}
.selectedfocus rect{
  fill: skyblue;
  stroke-width:2;
}
p.rel{
  border-style: dotted none none none;
  border-width: 0.3px;
  border-spacing: 0px;
}
rect.rel{
    stroke-width: 0.3px;
    stroke-dasharray: 0 300;
}
</style>
